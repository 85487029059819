import React from 'react';

import { Button as MuiButton } from 'gatsby-theme-material-ui';
import { ButtonProps as MuiButtonProps } from '@material-ui/core';
import { GatsbyProps } from 'gatsby-material-ui-components/lib/patch-base-button-components';

import { makeStyles } from '@material-ui/core/styles';

const useButtonStyles = makeStyles({
  root: {
    minWidth: 260,
    '&:hover, &:focus': {
      '& > span > span': {
        animation: '$blinker 1s steps(2, jump-none) infinite',
      },
    },
  },
  blinker: {
    animation: '0',
  },
  '@keyframes blinker': {
    to: { color: 'transparent' },
    from: { color: 'inherit' },
  },
});

interface ButtonProps {
  underscore?: boolean;
  children?: React.ReactNode;
}

const Button: React.FC<MuiButtonProps & GatsbyProps & ButtonProps> = ({
  underscore,
  children,
  ...other
}) => {
  const buttonStyles = useButtonStyles();

  return (
    <MuiButton className={buttonStyles.root} {...other}>
      {children}
      {underscore && (
        <span className={buttonStyles.blinker} aria-hidden="true">
          &nbsp;_
        </span>
      )}
    </MuiButton>
  );
};

Button.defaultProps = {
  underscore: true,
};

export default Button;
